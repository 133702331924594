

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MembroCard',
  props: {
      imgPath: String,
      nome: String,
      alcunha: String,
      instrumento: String,
      curso: String,
      ano: String,
  }
});
